import LinguaCard from 'components/LinguaCard';

const Linguas = () => {

  return (
          <LinguaCard/>
  );
};

export default Linguas;
