import ProgramacaoCard from 'components/ProgramacaoCard';

const Programacao = () => {

  return (
          <ProgramacaoCard/>
  );
};

export default Programacao;
